<template>
  <article class="animate-fade-in">
    <div class="text-center flex justify-center flex-col items-center">
      <img src="~/assets/img/logos/MetaWin-logo-white.svg" class="w-60 mb-5">
      <p class="mb-2">Sorry, MetaWin.com is not available to players in{{ countryPrefix }} {{ countryName }}.</p>
      <p class="text-sm text-slate-200">Please visit <NuxtLink class="text-[#00D1FF]" to="https://discord.gg/the-arena" target="_blank">The ARENA Discord</NuxtLink> for support.</p>
    </div>
  </article>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { useLocationStore } from '@/store/location';

const locationStore = useLocationStore();
const { country, } = storeToRefs(locationStore);

const countryPrefix = computed(() => ['US', 'GB',].includes(country.value) ? ' the' : '');
const countryName = computed(() => {
  try {
    return new Intl.DisplayNames('en', { type: 'region', }).of(country.value);
  } catch (e) {
    console.error(e);
    return country.value;
  }
});
</script>
