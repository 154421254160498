<template>
  <article class="animate-fade-in">
    <div class="text-center flex justify-center flex-col items-center">
      <img
        v-if="data?.showLogo"
        src="~/assets/img/logos/MetaWin-logo-white.svg"
        class="w-60 mb-5"
      >
      <div v-if="data.heading" class="mb-1">{{ data.heading }}</div>
      <div
        class="text-sm"
        :class="[ data.heading ? 'text-slate-200' : 'font-bold' ]"
      >
        {{ data.message }} ({{ country }})
      </div>
    </div>
    <ButtonButton
      type="button"
      class="block w-full mt-8"
      @click.prevent.stop="close()"
    >
      {{ data.buttonLabel || 'Close' }}
    </ButtonButton>
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useLocationStore } from '@/store/location';

const emits = defineEmits([
  'close',
]);

const uiStore = useUiStore();
const {
  errorModalData,
  gamesRestrictedModalDismissed,
} = storeToRefs(uiStore);

const locationStore = useLocationStore();
const { country, } = storeToRefs(locationStore);

const { $rollbar, $sanity, } = useNuxtApp();

const data = ref();

fetchCmsData();

function close() {
  emits('close');
  errorModalData.value = null;
  gamesRestrictedModalDismissed.value = true;
}

function fetchCmsData() {
  $sanity.fetch(
    `*[_type == "modals" && modalId == "locationRestrictedForGames"][0] {
      heading,
      message,
      buttonLabel,
      showLogo,
    }`
  ).then((res) => {
    data.value = res;
  }).catch((err) => {
    $rollbar.error('PromoBanners fetchCmsData::', err);
  });
}
</script>
