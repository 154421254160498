<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">Confirm Request</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>There is a pending change request for the selected wallet.</p>
      </div>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>You must follow the provided email instructions before authenticating with your new wallet address.</p>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-12"
        @click.prevent="emits('close')"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script setup>
const emits = defineEmits(['close',]);
</script>
