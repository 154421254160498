<template>
  <ModalMain
    priority
    :hide-close="locationRestricted"
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <ModalErrorContentInsufficientWalletBalance
        v-if="errorModalType === 'InsufficientWalletBalance'"
        @close="close()"
      />

      <ModalErrorContentLocationRestricted
        v-if="locationRestricted"
        @close="close()"
      />

      <ModalErrorContentLocationRestrictedForGames
        v-if="errorModalType === 'LocationRestrictedForGames'"
        @close="close()"
      />

      <ModalErrorContentGeneral
        v-if="errorModalType === 'General'"
        @close="close()"
      />

      <ModalErrorContentLoginFailed
        v-if="errorModalType === 'LoginFailed'"
        @close="close()"
      />

      <ModalErrorContentUserDisabled
        v-if="errorModalType === 'UserDisabled'"
        @close="close()"
      />

      <ModalErrorContentUserAliasChangeRequest
        v-if="errorModalType === 'UserAliasChangeRequest'"
        @close="close()"
      />

      <ModalErrorContentMinigameNotFound
        v-if="errorModalType === 'MinigameNotFound'"
        @close="close()"
      />

      <ModalErrorContentGamesBlocked
        v-if="errorModalType === 'GamesBlocked'"
        @close="close()"
      />

      <ModalErrorContentFree
        v-if="errorModalType === 'Free'"
        @close="close"
      />
    </div>
  </ModalMain>
</template>

<script setup>
const uiStore = useUiStore();
const {
  showErrorModal,
  errorModalType,
} = storeToRefs(uiStore);

const { $gtmCustomEvent, } = useNuxtApp();
const router = useRouter();

const locationRestricted = computed(() => errorModalType.value === 'LocationRestricted');

function close() {
  showErrorModal.value = false;
  errorModalType.value = null;

  if (errorModalType.value === 'GamesBlocked') {
    router.push('/');
  }
}

watch(
  () => errorModalType.value,
  (newVal) => {
    $gtmCustomEvent({
      event: 'modal_show',
      action: 'modalOpen',
      modalName: 'Error',
      value: newVal,
    });
    if (newVal === 'closeModal') {
      close();
    }
  }
);
</script>
