<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">Game not found</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>This game is no longer active.</p>
      </div>
      <ButtonLink
        to="/"
        class="block w-full mt-5"
      >
        Browse Competitions
      </ButtonLink>
    </div>
  </article>
</template>
