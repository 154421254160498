<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">Location Restricted</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>Due to licensing restrictions, Instant Win and Live Games are not available in your location. ({{ country }})</p>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-10"
        @click.prevent="emits('close')"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useLocationStore } from '@/store/location';

const locationStore = useLocationStore();
const { country, } = storeToRefs(locationStore);

const emits = defineEmits([
  'close',
]);
</script>
