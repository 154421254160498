<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">{{ errorModalTitle || 'Oops' }}</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <template v-if="!errorModalMessage">
          <p>Something went wrong...</p>
          <p>Try again later.</p>
        </template>
        <p v-else>
          {{ errorModalMessage }}
        </p>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-12"
        @click.prevent.stop="close()"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script setup>
const emits = defineEmits([
  'close',
]);

const uiStore = useUiStore();
const {
  errorModalData,
  errorModalTitle,
  errorModalMessage,
} = storeToRefs(uiStore);

function close() {
  emits('close');
  errorModalData.value = null;
  errorModalTitle.value = null;
  errorModalMessage.value = null;
}
</script>
